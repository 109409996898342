<template>
  <b-card no-body>
    <div class="m-2">
      <b-row class="justify-content-end">
        <!-- Select Status -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <v-select
              v-model="tripStatus"
              :options="status"
              class="status-filter-select"
              placeholder="Select Status"
              @input="getRequestedQuery()"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      hover
      responsive
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :items="tripList"
      :fields="tableColumns"
      :busy="isTableLoading"
    >
      <template #table-busy>
        <div class="text-center text-primary m-2">
          <b-spinner class="align-middle" />
          <strong> Loading...</strong>
        </div>
      </template>
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="getTripOptions(data.item.search_result, data.item.id, data.item.status)"
          />
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            class="action-menu"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal.trip-modal
              @click="getRequestedAction(data.item.id,'Book Trip', 'book',
                                         'b2e_dashboard/trip_request/book/',
                                         'post', 'book')"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">Book Trip</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal.trip-modal
              @click="getRequestedAction(data.item.id,'Cancel Trip', 'cancel',
                                         'b2e_dashboard/trip_request/cancel/',
                                         'post', 'cancel')"
            >
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Cancel Trip</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template #cell(status)="data">
        <b-badge
          pill
          class="cursor-pointer"
          :variant="formatClassName(data.item.status)"
        >
          {{ data.item.status }}
        </b-badge>
      </template>
    </b-table>
    <TablePagination
      :offset="meta.offset"
      :total="meta.total"
      :limit="meta.limit"
      :list-data="tripList"
      @paginate="paginate"
    />
    <TripAction
      :modal-data="tripActionData"
      @getTripList="getTripList"
    />
    <TripActionSidebar
      :trip-data="tripActionData"
      :trip-flight-options="tripFlightOptions"
      @getTripList="getTripList"
      @resetTripData="resetTripData"
    />
  </b-card>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import TablePagination from '@/components/common/TablePagination.vue'
import TripAction from '@/views/bookings/flight/TripAction.vue'
import TripActionSidebar from '@/views/bookings/flight/TripActionSidebar.vue'

export default {
  name: 'TripList',
  components: {
    TablePagination,
    TripAction,
    TripActionSidebar,
  },
  mixins: [helper],
  data() {
    return {
      isTableLoading: false,
      tripStatus: '',
      tripList: [],
      tripFlightOptions: [],
      tripActionData: {},
      status: ['PENDING', 'REJECTED', 'UPCOMING', 'COMPLETED'],
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        { key: 'actions', label: '' },
        {
          key: 'created_at', label: 'Booking time', thClass: 'lg', formatter: value => this.formatDate(value),
        },
        {
          key: 'search_request.trips[0].', label: 'Flight', thClass: 'md', formatter: value => `${value.origin}-${value.destination}` || 'N/A',
        },
        {
          key: 'status', label: 'Status', thClass: 'sm',
        },
        {
          key: 'search_request.trips[0].preferred_time', label: 'Depart', thClass: 'sm',
        },
        {
          key: 'search_request.cabin_class', label: 'Class', thClass: 'sm',
        },
      ],
    }
  },
  computed: {
    filterQuery() {
      return this.tripStatus ? `&status=${this.tripStatus}` : ''
    },
  },
  mounted() {
    this.getTripList()
  },
  methods: {
    getTripList() {
      this.isTableLoading = true
      api.getData(`b2e_dashboard/trip_request/?offset=${this.meta.offset}${this.filterQuery}`, true).then(response => {
        if (response.data.status) {
          this.tripList = response.data.result.results
          this.meta = response.data.result.meta
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch().finally(() => {
        this.isTableLoading = false
      })
    },
    getRequestedQuery() {
      this.isTableLoading = true
      this.meta.offset = 0
      this.getTripList()
    },
    getRequestedAction(id, title, text, apiEndpoint, apiMethod, status) {
      this.tripActionData = {
        id,
        title,
        text,
        apiEndpoint,
        apiMethod,
        status,
      }
      if (status === 'change_date' || status === 'change_route') {
        this.toggleTripSidebar()
      }
    },
    getTripOptions(options, tripId, bookingStatus) {
      this.tripFlightOptions = options
      this.tripActionData = {
        status: 'flight_options',
        id: tripId,
        text: 'Trip Details',
        bookingStatus,
      }
      this.toggleTripSidebar()
    },
    paginate(offset) {
      this.meta.offset = offset
      this.getTripList()
    },
    toggleTripSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'trip-sidebar-form')
    },
    resetTripData() {
      this.tripActionData = null
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
