<template>
  <b-sidebar
    id="trip-sidebar-form"
    ref="trip-sidebar"
    class="trip-sidebar"
    width="55%"
    :title="tripData.title"
    bg-variant="white"
    right
    shadow
    backdrop
    no-header
    @shown="isSidebarHidden = false"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
          class="mb-0"
        >
          {{ tripData.text }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-card
        no-body
        class="container my-1"
      >
        <DateChangeForm
          v-if="tripData.status === 'change_date'"
          :trip-form-data="tripData"
          :is-sidebar-hidden="isSidebarHidden"
        />
        <RouteChangeForm
          v-if="tripData.status === 'change_route'"
          :trip-form-data="tripData"
          :is-sidebar-hidden="isSidebarHidden"
        />
        <b-tabs
          pills
          class="mt-1"
        >
          <b-tab
            active
          >
            <template #title>
              <feather-icon
                icon="GitBranchIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Flights</span>
            </template>
            <TripOptionList
              v-if="tripData.status === 'flight_options'"
              :flight-list="tripFlightOptions"
              :trip-id="tripData.id"
              :status="tripData.bookingStatus"
              @getTripList="getTripList"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import DateChangeForm from './DateChangeForm.vue'
import RouteChangeForm from './RouteChangeForm.vue'
import TripOptionList from '@/views/bookings/flight/TripOptionList.vue'

export default {
  name: 'TripActionSidebar',
  components: {
    TripOptionList,
    RouteChangeForm,
    DateChangeForm,
  },
  props: {
    tripData: {
      type: Object,
    },
    tripFlightOptions: {
      type: Array,
    },
  },
  data() {
    return {
      isSidebarHidden: true,
    }
  },
  methods: {
    getTripList() {
      this.$emit('getTripList')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
