<template>
  <b-modal
    id="trip-modal"
    ref="trip-modal"
    ok-only
    ok-variant="primary"
    modal-class="modal-primary"
    centered
    :title="modalData.title"
  >
    <b-card-text>
      Are you sure you want to {{ modalData.text }} this trip ?
    </b-card-text>
    <template #modal-footer>
      <b-button
        variant="primary"
        :disabled="isActionLoading"
        @click="submitData"
      >
        <b-spinner
          v-if="isActionLoading"
          small
        />
        Confirm
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'TripAction',
  mixins: [helper],
  props: {
    modalData: {
      type: Object,
    },
  },
  data() {
    return {
      isActionLoading: false,
    }
  },
  methods: {
    submitData() {
      let apiRequest = ''
      const formData = {
        id: this.modalData.id,
      }
      if (this.modalData.status === 'book') {
        formData.status = 'BOOKED'
      }
      this.isActionLoading = true
      apiRequest = api.postData(this.modalData.apiEndpoint, true, formData)
      this.getResponse(apiRequest)
    },
    getResponse(apiRequest) {
      apiRequest.then(response => {
        if (response.data.status) {
          this.$emit('getTripList')
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Your request has been successfully processed')
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', `${JSON.stringify(response.data.error.message)}`)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
      }).finally(() => {
        this.isActionLoading = false
        this.$refs['trip-modal'].hide()
      })
    },
    resetData() {
      this.departmentData = {
        name: null,
        description: null,
      }
    },
    fetchTripList() {
      this.$emit('getTripList')
    },
  },
}
</script>

<style scoped>

</style>
