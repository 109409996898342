<template>
  <b-form>
    <validation-observer ref="trip-form">
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="departure"
            rules="required"
          >
            <label for="departure">Departure Date</label>
            <b-form-datepicker
              id="departure"
              v-model="departure"
              reset-button
              class="mb-2"
              @input="arrival=setEndDate(departure, arrival)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <label for="arrival">Arrival Date</label>
          <b-form-datepicker
            id="arrival"
            v-model="arrival"
            reset-button
            class="mb-2"
            @input="departure=setStartDate(departure, arrival)"
          />
        </b-col>
        <b-col md="12">
          <b-form-group
            id="reason"
            label="Please give your reason"
            label-for="reason"
          >
            <b-input-group>
              <b-form-textarea
                id="textarea"
                v-model="reason"
                rows="3"
                max-rows="6"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <div class="d-flex justify-content-start">
      <b-button
        variant="primary"
        class="mr-2"
        :disabled="isLoading"
        @click="submitAction"
      >
        <b-spinner
          v-if="isLoading"
          small
        />
        Done
      </b-button>
      <b-button
        variant="outline-primary"
        @click="hideTripSidebar()"
      >
        Close
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'
import api from '@/libs/api'

export default {
  name: 'DateChangeForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [helper, calendar],
  props: {
    tripFormData: {
      type: Object,
    },
    isSidebarHidden: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      required,
      isLoading: false,
      departure: null,
      arrival: null,
      reason: null,
    }
  },
  watch: {
    isSidebarHidden(val) {
      if (val) {
        this.resetData()
      }
    },
  },
  methods: {
    submitAction() {
      let apiRequest = ''
      const formData = {
        trip: this.tripFormData.id,
        departure_date: this.departure,
        arrival_date: this.arrival,
        reason: this.reason,
      }
      this.$refs['trip-form'].validate().then(success => {
        if (success) {
          this.isLoading = true
          apiRequest = api.postData(this.tripFormData.apiEndpoint, true, formData)
          this.getResponse(apiRequest)
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
    getResponse(apiRequest) {
      apiRequest.then(response => {
        if (response.data.status) {
          this.$emit('getTripList')
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Your request has been successfully processed')
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', `${JSON.stringify(response.data.error.message)}`)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
      }).finally(() => {
        this.isLoading = false
      })
    },
    resetData() {
      this.departure = null
      this.arrival = null
      this.reason = null
    },
    fetchTripList() {
      this.$emit('fetchTripList')
    },
    hideTripSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'trip-sidebar-form')
      this.resetData()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
